<template>
    <v-container fluid>
        <v-form ref="form" lazy-validation>
            <v-row>
                <v-col>
                    <h1>Create Supplemental Proposal</h1>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.name"
                        label="Name*"
                        placeholder="Name"
                        hint="Minimum of 3 characters required"
                        persistent-hint
                        validate-on="blur"
                        :rules="[
                            rules.required, 
                            rules.min(3), 
                            rules.max(64)
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-select
                        v-model="entityCore.status"
                        :items="statusesList"
                        item-title="title"
                        item-value="value"
                        label="Status*"
                        placeholder="Status"
                        @blur="convertEmptyToNull('status')"
                        :rules="[
                            rules.required,
                        ]"
                    >
                    </v-select>
                </v-col>
                <v-col>
                    <v-select
                        v-model="entityCore.unit_price_book_uid"
                        :items="unitPriceBooksList.items"
                        item-title="_name"
                        item-value="_id"
                        label="Unit Price Book*"
                        placeholder="Unit Price Book"
                        @blur="convertEmptyToNull('unit_price_book_uid')"
                        @focus="fetchList('unit_price_book', 'owner-' + entityAncestry.owner)"
                        :loading="unitPriceBooksList.loading"
                        :disabled="unitPriceBooksList.disabled"
                        :hint="unitPriceBooksList.hint"
                        persistent-hint
                        :rules="[
                            rules.required,
                        ]"
                    >
                    </v-select>
                </v-col>
            </v-row>

            <v-row><v-col><v-divider></v-divider></v-col></v-row>

            <v-row>
                <v-col>
                    <h3>Subcontractors</h3>
                </v-col>
                <v-col class="d-flex ga-4 justify-end">
                    <v-btn color="primary" variant="outlined" @click="openSubcontractorDialog()">
                        Add
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-data-table
                        :headers="subcontractorsListHeaders"
                        :items="subcontractorsList"
                        item-key="_id"
                        :items-per-page="100"
                        :hide-default-footer="true"
                        disable-sort
                        >
                    </v-data-table>
                </v-col>
            </v-row>

            <template v-if="$hasPermission(`supplemental_proposal:create`)">
                <v-row><v-col><v-divider></v-divider></v-col></v-row>

                <v-row>
                    <v-col>
                        <h3>External Estimates</h3>
                    </v-col>
                    <v-col class="d-flex ga-4 justify-end">
                        <v-btn color="primary" variant="outlined" @click="openExternalEstimateDialog()">
                            Add
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-data-table
                            :headers="externalEstimatesListHeaders"
                            :items="externalEstimatesList"
                            item-key="_id"
                            :items-per-page="100"
                            :hide-default-footer="true"
                            disable-sort
                            >
                        </v-data-table>
                    </v-col>
                </v-row>
            </template>
            

            <FormCreateFooter 
                :form="form"
                :entityCore="entityCore"
                :current="current"
                :parent="parent" 
            />
            
        </v-form>

        <SubcontractorDialog
            :show="showSubcontractorDialog"
            :currentSubcontractors="entityCore.subcontractors" 
            @subcontractorAdded="addSubcontractor"
            @closeDialog="closeSubcontractorDialog"
        />
        
        <ExternalEstimateDialog
            :show="showExternalEstimateDialog"
            :currentExternalEstimates="entityCore.external_estimates" 
            :adjustmentFactors="adjustmentFactors"
            @externalEstimateAdded="addExternalEstimate"
            @closeDialog="closeExternalEstimateDialog"
        />
    </v-container>
</template>
<script setup>
/* 
Initial Setup Strategy:
Start UPB field in disabled state.
Create a computed property for upbListFromContract for the items, hint, and disabled state.
Load parent document, then any ancestry by closest to farthest. This happens in the breadcrumbs component.
After parent is loaded (isCurrentEntityLoaded), look up the contract_uid.
Get the contract_uid document.
Get associated_unit_price_books from the contract_uid document.
Update the field's hint to show the contract name or error if there are no UPBs.
Enable field if there are UPBs.
On input focus, fetch the list of unit price books from the owner via fetchSingle.
    How do you track the loading state of the fetchSingle call if there are multiple? If any of the UPB IDs are missing, the field should be in a loading state still.
*/
import { useStore } from 'vuex';
import { ref, reactive, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import rules from "@/assets/modules/rules.js";
import statusesList from "@/assets/selects/project-work-order-proposal-status.json";
import FormCreateFooter from "@/components/interface/FormCreateFooter.vue";
import { useFieldValidation, useFetchList, useFetchSingle, useEntityAncestry } from '@/assets/modules/composables';




const store = useStore();
const route = useRoute();
const form = ref();
const current = {
    type: "supplemental_proposal"
};
const parent = {
    id: route.params.parent_id,
    type: "supplemental_work_order"
};
const entityCore = ref({
    external_estimates: [],
});
const { convertEmptyToNull } = useFieldValidation(entityCore);
const isLoading = reactive({});
const alreadyFetchedData = [];
const { fetchList } = useFetchList(isLoading, alreadyFetchedData);
const { fetchSingle } = useFetchSingle(isLoading, alreadyFetchedData);
const { entityAncestry } = useEntityAncestry();



const workOrderDoc = computed(() => {
    return store.getters.getEntityDocument(entityAncestry.value.work_order);
});

const contractDoc = computed(() => {
    return store.getters.getEntityDocument(workOrderDoc.value?.core.contract_uid);
});

const adjustmentFactors = computed(() => {
    console.log("adjustmentFactors",contractDoc.value?.core.adjustment_factors)
    return contractDoc.value?.core.adjustment_factors;
});

const upbListFromContract = computed(() => {
    return contractDoc.value?.core.associated_unit_price_books;
});

watch(workOrderDoc, async () => {
    if(workOrderDoc.value._id){
        fetchSingle("contract", workOrderDoc.value.core.contract_uid);
    }
})



// We' loading all of the UPBs on input click and using this to filter them. We should probably tighten this up to only fetch the needed UPBs.
const unitPriceBooksList = computed(() => {
    // Add a function to filter down the larger list of UPBs from an owner against the array of IDs for this contract's UPBs instead of having a whole new store action.
    // ex) const allUpbsOfOwner = store.getters.getChildList("owner." + entityAncestry.value.owner + ".", "unit_price_book");
    // ex) const items = allUpbsOfOwner.filter(upb => upbListFromContract.value.includes(upb._id));
    const items = store.getters.getChildListFromIds("owner." + entityAncestry.value.owner + ".", "unit_price_book", upbListFromContract.value);
    const hint = contractDoc.value?._name ? `List of unit price books from ${contractDoc.value?._name} via ${workOrderDoc.value?._name}` : 'List of unit price books'
    const disabled = !contractDoc.value?._name;
    const loading = isLoading['unit_price_book_owner-' + entityAncestry.value.owner];
    return {
        items: items,
        hint: hint,
        disabled: disabled,
        loading: loading,
    };
})





import SubcontractorDialog from "@/components/entity/proposal/SubcontractorDialog.vue";
const showSubcontractorDialog = ref(false);
entityCore.value.subcontractors = [];
function addSubcontractor(subcontractor){
    entityCore.value.subcontractors.push(subcontractor);
}
const subcontractorsListHeaders = [
    {
        title: "Subontractor",
        key: "subcontractor_uid",
        value: (row) => store.getters.getEntityDocument(row.subcontractor_uid)?._name,
    },
    {
        title: "Amount",
        key: "amount",
    },
];
function openSubcontractorDialog(){
    showSubcontractorDialog.value = true;
}
function closeSubcontractorDialog(){
    showSubcontractorDialog.value = false;
}
const subcontractorsList = computed(() => {
    return entityCore.value.subcontractors;
})


import ExternalEstimateDialog from "@/components/entity/proposal/ExternalEstimateDialog.vue";
const showExternalEstimateDialog = ref(false);
entityCore.value.external_estimates = [];
function addExternalEstimate(externalEstimate){
    entityCore.value.external_estimates.push(externalEstimate);
}
const externalEstimatesListHeaders = [
    {
        title: "Name",
        key: "name",
    },
    {
        title: "Amount",
        key: "amount",
    },
    {
        title: "Adjustment Factor",
        key: "default_adjustment_factor",
    },
    // {
    //     title: "NPP AJ",
    //     key: "npp_adjustment_factor",
    // },
];
function openExternalEstimateDialog(){
    showExternalEstimateDialog.value = true;
}
function closeExternalEstimateDialog(){
    showExternalEstimateDialog.value = false;
}
const externalEstimatesList = computed(() => {
    return entityCore.value.external_estimates;
})
</script>