<template>
    <v-container fluid>
        <ReadHeader :current="current" />
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`Phone`"
                :value="entityCore.phone"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Fax`"
                :value="entityCore.fax"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`Email`"
                :value="entityCore.email"
                ></KeyValue>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <KeyValue
                :title="`Address`"
                :value="entityCore.address_line_1"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Address Continued`"
                :value="entityCore.address_line_2"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`City`"
                :value="entityCore.city"
                ></KeyValue>
            </v-col>
            <v-col cols="3">
                <KeyValue
                :title="`State`"
                :value="entityCore.state"
                ></KeyValue>
            </v-col>
            <v-col cols="3">
                <KeyValue
                :title="`Zip`"
                :value="entityCore.zip"
                ></KeyValue>
            </v-col>
        </v-row>

        <TableList
            :current="current"
            :details="projectListDetails"
            isSearchable="true"
        />
        
        <TableList
            :current="current"
            :details="clientListDetails"
        />

        <TableList
            :current="current"
            :details="ownerContactListDetails"
        />
        
        <TableList
            :current="current"
            :details="locationListDetails"
        />
        
        <TableList
            :current="current"
            :details="unitPriceBookListDetails"
        />
        
        <TableList
            :current="current"
            :details="subcontractorListDetails"
        />
        
        <TableList
            :current="current"
            :details="contractListDetails"
        />
        
    </v-container>
</template>
<script setup>
import { useRoute} from 'vue-router';
import KeyValue from "@/components/interface/Key-Value.vue";
import ReadHeader from "@/components/interface/ReadHeader.vue";
import TableList from "@/components/interface/TableList.vue";
import { useEntityCore } from "@/assets/modules/composables.js";

const route = useRoute();
const { entityCore } = useEntityCore();

const current = {
    type: "owner",
    id: route.params.entity_id,
};

const projectListDetails = {
    listType: "project",
    heading: "Projects",
    tableHeaders: [
        // {
        //     title: "ID",
        //     key: "_id",
        // }
    ],
};
const clientListDetails = {
    listType: "client",
    heading: "Clients",
    tableHeaders: [
        // {
        //     title: "ID",
        //     key: "_id",
        // }
    ],
};
const ownerContactListDetails = {
    listType: "owner_contact",
    heading: "Owner Contacts",
    tableHeaders: [
        // {
        //     title: "ID",
        //     key: "_id",
        // }
    ],
};
const locationListDetails = {
    listType: "location",
    heading: "Locations",
    tableHeaders: [
        // {
        //     title: "ID",
        //     key: "_id",
        // }
    ],
};
const unitPriceBookListDetails = {
    listType: "unit_price_book",
    heading: "Unit Price Books",
    tableHeaders: [
        // {
        //     title: "ID",
        //     key: "_id",
        // }
    ],
};
const subcontractorListDetails = {
    listType: "subcontractor",
    heading: "Subcontractors",
    tableHeaders: [
        // {
        //     title: "ID",
        //     key: "_id",
        // }
    ],
};
const contractListDetails = {
    listType: "contract",
    heading: "Contracts",
    tableHeaders: [
        // {
        //     title: "ID",
        //     key: "_id",
        // }
    ],
};

</script>