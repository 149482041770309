<template>
    <v-row>
        <v-col class="d-flex ga-4 justify-end">
            <v-btn color="primary" variant="outlined" @click="formCancel(props.current.type,props.current.id)" :disabled="submitting">
                Cancel
            </v-btn>
            <v-btn color="primary" variant="flat" @click="updateFormSubmit()" :loading="submitting" :disabled="submitting">
                Save
            </v-btn>
        </v-col>
    </v-row>
</template>
<script setup>
import { defineProps, toRefs, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useFormCancel } from "@/assets/modules/composables.js";
const props = defineProps(['form', 'entityCore', 'current']);
const { form, entityCore } = toRefs(props);
const { formCancel } = useFormCancel();
const router = useRouter();
const store = useStore();
const submitting = ref(false);

async function updateFormSubmit(){
    const formValidation = await form.value.validate();
    if (!formValidation.valid) {
        throw new Error("Form validation failed");
    }
    submitting.value = true;
    const submission = await store.dispatch("entityGlobalUpdateWithoutRoute", {
        payload: entityCore.value,
        routing: {
            entityTypeToUpdate: props.current.type,
            entityId: props.current.id,
        }
    });
    if(submission?.success){
        if(props.current.type === "proposal" || props.current.type === "supplemental_proposal"){
            console.log("Generating calculations for proposal");
            store.dispatch("generateCalulations", {entityID: props.current.id});
        }
        router.push(`/${props.current.type}/${props.current.id}`);
    }else{
        throw new Error("Form submission failed.");
    }
}
</script>