export const entityTitles = {
    owner: {
        singular: "Owner",
        plural: "Owners",
    },
    contractor: {
        singular: "Contractor",
        plural: "Contractors",
    },
    dashboard: {
        singular: "Dashboard",
        plural: "Dashboard",
    },
    work_order: {
        singular: "Job Order",
        plural: "Job Orders",
    },
    supplemental_work_order: {
        singular: "Supplemental Job Order",
        plural: "Supplemental Job Orders",
    },
    supplemental_proposal: {
        singular: "Supplemental Proposal",
        plural: "Supplemental Proposals",
    },
    location: {
        singular: "Location",
        plural: "Locations",
    },
    project: {
        singular: "Project",
        plural: "Projects",
    },
    proposal: {
        singular: "Proposal",
        plural: "Proposals",
    },
    contract: {
        singular: "Contract",
        plural: "Contracts",
    },
    client: {
        singular: "Client",
        plural: "Clients",
    },
    client_contact: {
        singular: "Client Contact",
        plural: "Client Contacts",
    },
    contractor_contact: {
        singular: "Contractor Contact",
        plural: "Contractor Contacts",
    },
    option: {
        singular: "Option",
        plural: "Options",
    },
    owner_contact: {
        singular: "Owner Contact",
        plural: "Owner Contacts",
    },
    subcontractor: {
        singular: "Subcontractor",
        plural: "Subcontractors",
    },
    unit_price_book: {
        singular: "Unit Price Book",
        plural: "Unit Price Books",
    },
    adjustment_factor: {
        singular: "Adjustment Factor",
        plural: "Adjustment Factors",
    },
}


