<template>
    <LoadingBar/>
    <v-row>
        <v-col>
            <h1>{{ name || "&nbsp;" }}</h1>
        </v-col>
        <v-col class="d-flex ga-4 justify-end" v-if="$hasPermission(`${current.type}:update`)">
            <v-btn color="primary" variant="flat" @click="navigateToUpdate()">
                Update
            </v-btn>
        </v-col>
    </v-row>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import LoadingBar from "@/components/interface/LoadingBar.vue";
const router = useRouter();
const props = defineProps(['current']);
const store = useStore();


const name = computed(() => { 
    return store.getters.getEntityDocument(props.current.id)?._name;
})

function navigateToUpdate(){
    router.push(`/${props.current.type}/${props.current.id}/update`);
}
</script>