<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h2>Contracts</h2>
        <p class="text-caption">{{ contracts.description }}</p>
        <v-data-table
          :headers="contractHeaders"
          :items="contracts.list"
          :search="contractSearch"
          item-value="key"
          @click:row="tableRowClick"
          hover
          :items-per-page="10"
        >
        <template v-slot:[`item.contractor`]="{ item }">
          {{ nameByID(item.contractor_id) }}
        </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h2>Job Orders</h2>
        <p class="text-caption">{{ workOrders.description }}</p>
        <v-data-table
          :headers="workOrderHeaders"
          :items="workOrders.list"
          :search="workOrderSearch"
          item-value="key"
          @click:row="tableRowClick"
          hover
          :items-per-page="10"
        >
          <template v-slot:[`item.project_number`]="{ item }">
            {{ getProjectNumber(item.key) }}
          </template>
          <template v-slot:[`item.project_name`]="{ item }">
            {{ getProjectName(item.key) }}
          </template>
          <template v-slot:[`item.work_order_stage`]="{ item }">
            {{ item.stage }}
          </template>
          <template v-slot:[`item.work_order_status`]="{ item }">
            {{ item.status }}
          </template>
          <template v-slot:[`item.owner_project_managers`]="{ item }">
            <div v-html="combineOwnerPMs(item)"></div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const formObjectTemplate = {
  ownerID: "667efa9824d90ffe6b44a366",
};
export default {
  name: "DashboardView",
  data() {
    return {
      defaultEntityObject: formObjectTemplate, // This is the default object that will be used if there is no entity object to get
      localEntityObject: formObjectTemplate, // This is the object that will be used in the <template> to display the entity object
      contractSearch: "",
      contractHeaders: [
        {
          title: "Name",
          key: "name",
        },
        {
          title: "Contractor",
          key: "contractor",
        },
        {
          title: "Contract Value",
          key: "contract_value",
        },
        {
          title: "NTP Total",
          key: "ntp_total",
        },
        {
          title: "Remaining Capacity",
          key: "remaining_capacity",
        },
      ],
      workOrderSearch: "",
      workOrderHeaders: [
        {
          title: "Project #",
          key: "project_number",
        },
        {
          title: "Project Name",
          key: "project_name",
        },
        {
          title: "WO #",
          key: "work_order_number",
        },
        {
          title: "WO Name",
          key: "name",
        },
        {
          title: "Owner PMs",
          key: "owner_project_managers",
        },
        {
          title: "WO Status",
          key: "work_order_status",
        },
        {
          title: "WO Stage",
          key: "work_order_stage",
        },
      ],
    };
  },
  mounted() {
    // console.log(this.localEntityObject.ownerID);
  },
  methods: {
    getProjectNumber(workOrderID) {
      const workOrderNavPath = this.$store.getters.getEntityByID(workOrderID)?.navPath;
      const projectID = this.generateEntityAncestry(workOrderNavPath)?.obj?.project?.id
      const projectNumber = this.$store.getters.getEntityByID(projectID)?.entityObj?.joc_project_number;
      return projectNumber;
    },
    getProjectName(workOrderID) {
      const workOrderNavPath = this.$store.getters.getEntityByID(workOrderID)?.navPath;
      const projectID = this.generateEntityAncestry(workOrderNavPath)?.obj?.project?.id
      return this.nameByID(projectID);
    },
    combineOwnerPMs(owner) {
      let ownerPMs = [
        this.nameByID(owner.owner_project_manager_1_id),
        this.nameByID(owner.owner_project_manager_2_id),
        this.nameByID(owner.owner_project_manager_3_id),
      ];
      ownerPMs = ownerPMs.filter((item) => item !== undefined);
      return ownerPMs.join(",<br> ");
    },
  },
  computed: {
    ownerID(){
      const ownerID = this.localEntityObject.ownerID;
      return ownerID;
    },
    ownerObject(){
      const ownerObject = this.$store.getters.getEntityByID(this.ownerID).entityObj;
      return ownerObject
    },
    ownerName(){
      return this.nameByID(this.ownerID)
    },
    contracts() {
      const objectList = this.ownerObject?.contract;
      const columns = ["name", "contractor_id", "contract_value"];
      const list = this.generateTableListArray(
        objectList,
        columns
      );
      return {
        list: list,
        description: `Contracts from ${this.ownerName}`,
      };
    },
    workOrders() {
      const projectList = this.ownerObject?.project;
      const workOrderIDs = [];
      let list = [];
      const columns = [
        "name",
        "stage",
        "status",
        "owner_project_manager_1_id",
        "owner_project_manager_2_id",
        "owner_project_manager_3_id",
        "work_order_number",
      ];
      const objectList = {};
      if(projectList){
        const workOrders = Object.values(projectList).map(project => project?.work_order);
        workOrders.forEach(workOrder => {
          if(workOrder){
            const workOrderIds = Object.keys(workOrder);
            workOrderIDs.push(...workOrderIds);
          }
        });
      }
      workOrderIDs.forEach(workOrderID => {
        objectList[workOrderID] = this.$store.getters.getEntityByID(workOrderID).entityObj;
      });
      list = this.generateTableListArray(
        objectList,
        columns
      );
      return {
        list: list,
        description: `Job Orders from ${this.ownerName}`,
      };
    },
  },
  mixins: [entityMixins, entityReadMixins],
};
import entityMixins from "@/assets/mixins/entityMixins.js";
import entityReadMixins from "@/assets/mixins/entityReadMixins.js";
</script>

<style scoped>
.v-container + .v-container {
  margin-top: 20px;
}
</style>
