<template>
    <v-container fluid>
        <LoadingBar/>
        <v-form ref="form" lazy-validation>
            <v-row>
                <v-col>
                    <h1>Update Proposal</h1>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.name"
                        label="Name*"
                        placeholder="Name"
                        hint="Minimum of 3 characters required"
                        persistent-hint
                        validate-on="blur"
                        :disabled="!isCurrentEntityLoaded"
                        :rules="[
                            rules.required, 
                            rules.min(3), 
                            rules.max(64)
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-select
                        v-model="entityCore.status"
                        :items="statusesList"
                        item-title="title"
                        item-value="value"
                        label="Status*"
                        placeholder="Status"
                        @blur="convertEmptyToNull('status')"
                        :disabled="!isCurrentEntityLoaded"
                        :rules="[
                            rules.required,
                        ]"
                    >
                    </v-select>
                </v-col>
                <v-col>
                    <v-select
                        v-model="entityCore.unit_price_book_uid"
                        :items="unitPriceBooksList.items"
                        item-title="_name"
                        item-value="_id"
                        label="Unit Price Book*"
                        placeholder="Unit Price Book"
                        @blur="convertEmptyToNull('unit_price_book_uid')"
                        @focus="fetchList('unit_price_book', 'owner-' + entityAncestry.owner)"
                        :loading="unitPriceBooksList.loading"
                        :disabled="unitPriceBooksList.disabled"
                        :hint="unitPriceBooksList.hint"
                        persistent-hint
                        :rules="[
                            rules.required,
                        ]"
                    >
                    </v-select>
                </v-col>
            </v-row>

            <v-row><v-col><v-divider></v-divider></v-col></v-row>

            <v-row>
                <v-col>
                    <h3>Subcontractors</h3>
                </v-col>
                <v-col class="d-flex ga-4 justify-end">
                    <v-btn color="primary" variant="outlined" @click="openSubcontractorDialog()">
                        Add
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-data-table
                        :headers="subcontractorsListHeaders"
                        :items="entityCore.subcontractors"
                        item-key="_id"
                        :items-per-page="100"
                        :hide-default-footer="true"
                        disable-sort
                        >
                    </v-data-table>
                </v-col>
            </v-row>
            
            <template v-if="$hasPermission(`proposal:update`)">
                <v-row><v-col><v-divider></v-divider></v-col></v-row>

                <v-row>
                    <v-col>
                        <h3>External Estimates</h3>
                    </v-col>
                    <v-col class="d-flex ga-4 justify-end">
                        <v-btn color="primary" variant="outlined" @click="openExternalEstimateDialog()">
                            Add
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-data-table
                            :headers="externalEstimatesListHeaders"
                            :items="entityCore.external_estimates"
                            item-key="name"
                            :items-per-page="100"
                            :hide-default-footer="true"
                            disable-sort
                            >
                        </v-data-table>
                    </v-col>
                </v-row>
            </template>

            <FormUpdateFooter 
                :form="form"
                :entityCore="entityCore"
                :current="current"
            />

        </v-form>

        <SubcontractorDialog
            :show="showSubcontractorDialog"
            :currentSubcontractors="entityCore.subcontractors" 
            @subcontractorAdded="addSubcontractor"
            @closeDialog="closeSubcontractorDialog"
        />
        
        <ExternalEstimateDialog
            :show="showExternalEstimateDialog"
            :currentExternalEstimates="entityCore.external_estimates" 
            :adjustmentFactors="adjustmentFactors"
            @externalEstimateAdded="addExternalEstimate"
            @closeDialog="closeExternalEstimateDialog"
        />
    </v-container>
</template>
<script setup>
import { ref, computed, reactive, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import rules from "@/assets/modules/rules.js";
import statusesList from "@/assets/selects/project-work-order-proposal-status.json";
import { useIsCurrentEntityLoaded, useEntityCore, useEntityAncestry, useFetchSingle, useFetchList, useFieldValidation, useNameById } from "@/assets/modules/composables.js";
import LoadingBar from "@/components/interface/LoadingBar.vue";
import FormUpdateFooter from "@/components/interface/FormUpdateFooter.vue";

const route = useRoute();
const form = ref();
const { isCurrentEntityLoaded } = useIsCurrentEntityLoaded();
const { entityCore } = useEntityCore();
const store = useStore();
const isLoading = reactive({});
const alreadyFetchedData = [];
const { fetchSingle } = useFetchSingle(isLoading,alreadyFetchedData);
const { fetchList } = useFetchList(isLoading, alreadyFetchedData);
const { entityAncestry } = useEntityAncestry();
const { convertEmptyToNull } = useFieldValidation(entityCore);
const { nameById } = useNameById();
const current = {
    id: route.params.entity_id,
    type: "proposal"
};

const workOrderDoc = computed(() => {
    return store.getters.getEntityDocument(entityAncestry.value.work_order);
});

const contractDoc = computed(() => {
    return store.getters.getEntityDocument(workOrderDoc.value?.core.contract_uid);
});

const upbListFromContract = computed(() => {
    return contractDoc.value?.core.associated_unit_price_books;
});

watch(workOrderDoc, async () => {
    if(workOrderDoc.value?._id){
        fetchSingle("contract", workOrderDoc.value.core.contract_uid);
    }
}, { immediate: true });
const unitPriceBooksList = computed(() => {
    const items = store.getters.getChildListFromIds("owner." + entityAncestry.value.owner + ".", "unit_price_book", upbListFromContract.value);
    const hint = contractDoc.value?._name ? `List of unit price books from ${contractDoc.value?._name} via ${workOrderDoc.value?._name}` : 'List of unit price books'
    const disabled = !contractDoc.value?._name;
    const loading = isLoading['unit_price_book_owner-' + entityAncestry.value.owner];
    return {
        items: items,
        hint: hint,
        disabled: disabled,
        loading: loading,
    };
})

const adjustmentFactors = computed(() => {
    return contractDoc.value?.core.adjustment_factors;
});

watch(isCurrentEntityLoaded, async () => {
    if(isCurrentEntityLoaded.value){
        fetchSingle("unit_price_book", entityCore.value.unit_price_book_uid);
    }
})



watch(isCurrentEntityLoaded, async () => {
    if(isCurrentEntityLoaded.value){
        if(entityCore.value?.subcontractors){
            entityCore.value.subcontractors.forEach((subcontractor) => {
                fetchSingle("subcontractor", subcontractor.subcontractor_uid);
            });
        }
    }
})
import SubcontractorDialog from "@/components/entity/proposal/SubcontractorDialog.vue";
const showSubcontractorDialog = ref(false);
function addSubcontractor(subcontractor){
    entityCore.value.subcontractors.push(subcontractor);
}
const subcontractorsListHeaders = [
    {
        title: "Subontractor",
        key: "subcontractor_uid",
        value: (row) => nameById(row.subcontractor_uid),
    },
    {
        title: "Amount",
        key: "amount",
    },
];
function openSubcontractorDialog(){
    showSubcontractorDialog.value = true;
}
function closeSubcontractorDialog(){
    showSubcontractorDialog.value = false;
}



import ExternalEstimateDialog from "@/components/entity/proposal/ExternalEstimateDialog.vue";
const showExternalEstimateDialog = ref(false);
function addExternalEstimate(externalEstimate){
    console.log("externalEstimate",externalEstimate)
    entityCore.value.external_estimates.push(externalEstimate);
}
const externalEstimatesListHeaders = [
    {
        title: "Name",
        key: "name",
    },
    {
        title: "Amount",
        key: "amount",
    },
    {
        title: "Adjustment Factor",
        key: "default_adjustment_factor",
    },
    // {
    //     title: "NPP AJ",
    //     key: "npp_adjustment_factor",
    // },
];
function openExternalEstimateDialog(){
    showExternalEstimateDialog.value = true;
}
function closeExternalEstimateDialog(){
    showExternalEstimateDialog.value = false;
}
</script>