<template>
  <div class="key-value">
    <h5>{{ title }}</h5>
    <p v-html="parsedValue"></p>
  </div>
</template>

<script>
export default {
  name: "KeyValue",
  props: ["title", "value"],
  computed: {
    parsedValue() {
      if (!this.value) {
        return "<em style='font-size:12px;'>Not available</em>";
      }
      else if (typeof this.value !== "string") {
        return this.value;
      }
      
      const sanitizedText = this.sanitizeText(this.value);
      
      const formattedText = this.formatLineBreaks(sanitizedText);

      return formattedText;
    },
  },
  methods: {
    sanitizeText(input) {
      // Creates a DOM Parser to use
      const parser = new DOMParser();

      // Parses the string as HTML
      const parsedDocument = parser.parseFromString(input, 'text/html');

      // Extracts the text from the generated document
      const sanitizedText = parsedDocument.body.textContent || '';

      return sanitizedText;

    },
    formatLineBreaks(input) {
      return input.replace(/\r\n|\r|\n/g, '<br>');
    }
  },
};
</script>
