<template v-if="display.type">
    <v-row>
        <v-col v-if="display.type === 'action'">
            <v-btn color="primary" variant="outlined" @click="action" :disabled="waiting">
                {{ display.text }}
            </v-btn>
        </v-col>
        <v-col v-if="display.type === 'warning'">
            <v-alert :text="'Cannot convert contact to a user. ' + display.text" type="info" variant="tonal"></v-alert>
        </v-col>
    </v-row>
</template>
<script setup>
import { computed, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const store = useStore();

const entityId = route.params.entity_id;
const serverResponse = ref(null);
const waiting = ref(false);

async function action(){
    waiting.value = true;
    const actionResponse = await store.dispatch("changeUserStatus", entityId);
    serverResponse.value = actionResponse;
    waiting.value = false;
}


onMounted(async() => {
    serverResponse.value = await store.dispatch("getUserStatus", entityId);
});

const display = computed(() => {
    const displayObj = {};
    // The serverResponse.value will be limited to one of the following:
    switch (serverResponse.value) {
        case "canCreate":
            displayObj.type = "action";
            displayObj.text = "Create User";
            break;
        case "canDisable":
            displayObj.type = "action";
            displayObj.text = "Disable User";
            break;
        case "canEnable":
            displayObj.type = "action";
            displayObj.text = "Enable User";
            break;
        case "missingAll":
            displayObj.type = "warning";
            displayObj.text = "Missing email address and mobile phone number.";
            break;
        case "missingEmail":
            displayObj.type = "warning";
            displayObj.text = "Missing email address.";
            break;
        case "missingMobile":
            displayObj.type = "warning";
            displayObj.text = "Missing mobile phone number.";
            break;
    }
    return displayObj;
})
</script>