import { createStore } from 'vuex'
import axios from 'axios'
import { fetchAuthSession, getCurrentUser, signOut } from 'aws-amplify/auth';
// import styledLog from '../assets/modules/styledLog.js'
import router from './router';
import lodash from 'lodash';
// import getCurrentLine from 'get-current-line'

// Redeclaring the API URL so we can use it in this file without having to eslint for eternity. It is originally declared in the index.html file and passed in on build.
// eslint-disable-next-line
const LOCAL_API_BASE_URI = API_BASE_URI;


const getDefaultState = () => {
  return {
    authInfo: {},
    currentEntityLoaded: false,
    itemsBeingLoaded: [],
    currentFetch: [],
    documents: {},
    entity: {},
    pageLoads: {},
    appNotice: {
      message: "",
      show: false,
      color: null,
      timeout: 5000,
    },
    lambdaLogs: [],
    sourceOfTruth: {
      owner: {},
      contractor: {},
      owner_contact: {},
      contractor_contact: {},
      location: {},
      unit_price_book: {},
    },
  };
};

const store = createStore({
  state() {
    return getDefaultState();
  },
  getters: {
    getEntityDocument: (state) => (entityID) => {
      const entity = state.documents?.[entityID] || null;
      return entity;
    },
    getEntityCore: (state) => (entityID) => {
      const entity = state.documents?.[entityID]?.core || {};
      return entity;
    },
    getChildList: (state) => (queryPath, queryType) => {
      const documents = state.documents;
      const documentList = Object.values(documents).filter(doc => 
        doc._path.includes(queryPath) &&
        doc._type === queryType
      );
      documentList.sort((a, b) => {
          if (a._created.timestamp < b._created.timestamp) return -1;
          if (a._created.timestamp > b._created.timestamp) return 1;
          return 0;
      });
      return documentList;
    },
    getChildListFromIds: (state) => (queryPath, queryType, filterArray = []) => {
      const documents = state.documents;
      const documentList = Object.values(documents).filter(doc => 
        doc._path.includes(queryPath) &&
        doc._type === queryType &&
        filterArray.includes(doc._id)
      );
      documentList.sort((a, b) => {
          if (a._created.timestamp < b._created.timestamp) return -1;
          if (a._created.timestamp > b._created.timestamp) return 1;
          return 0;
      });
      return documentList;
    },
    getContractorList: (state) => (ownerID) => {
      const documents = state.documents;
      const documentList = Object.values(documents).filter(doc => {
        if (doc._type === "contractor") {
          return doc?.core?.associated_owners.includes(ownerID);
        }
        return false;
      });
      documentList.sort((a, b) => {
        if (a._created.timestamp < b._created.timestamp) return -1;
        if (a._created.timestamp > b._created.timestamp) return 1;
        return 0;
    });
      return documentList;
    },
    isCurrentEntityLoaded: (state) => {
      // console.log("currentEntityLoaded", state.currentEntityLoaded);
      return state.currentEntityLoaded;
    },
    /**
     * Get the top level entity objects for a given entityType
     * @param {string} entityType
     * @returns {object} - The top level entity objects for the given entityType
     */
    getTopLevelEntityObjects: (state) => (entityType) => {
      return state?.sourceOfTruth?.[entityType];
    },
    /**
     * Get the top level entity objects for a given entityType where the parent_id matches the parentID
     * @param {string} entityType
     * @param {string} parentID
     * @returns {object} - The top level entity objects for the given entityType after it is filtered by the parentID
     */
    getTopLevelEntityObjectsByParentID: (state) => (entityType, parentID) => {
      let entities = state?.sourceOfTruth?.[entityType];
      const filteredResults = {};
      if (entities) {
        Object.keys(entities).forEach((key) => {
          const item = entities[key];
          if (item.parent_id === parentID) {
            filteredResults[key] = item;
          }
        });
      }
      return filteredResults;
    },
    getEntityByID: (state) => (ID) => {
      
      // if(!(state.entity && state.entity[ID])){
      //   console.log("ID isn't loaded that you are accessing");
      // }
      
      // Use entity to look up the entity by its ID
      return state?.entity?.[ID] || {};
    },
    getItemsBeingLoaded: (state) => {
      return state.itemsBeingLoaded;
    },
    getCurrentFetch: (state) => {
      return state.currentFetch;
    },
    getAppNotice: (state) => {
      return state.appNotice;
    },
    getAccesstoken: (state) => {
      return state.authInfo.accessToken;
    },
    getUserPermissions: (state) => {
      return state.authInfo.permissions;
    },
    getAuthInfo: (state) => (item) => {
      return state.authInfo[item];
    },
    getAPIheaders: (state) => {
      return {
        "Content-Type": "application/json",
        Authorization: state.authInfo.accessToken,
      };
    },
    getModelLoaded: (state) => ( path ) =>{
      console.log(path);
      return state.pageLoads.all;
    }
  },
  actions: {
    setCurrentEntityLoaded(context, value) {
      context.state.currentEntityLoaded = value;
    },
    async pdfGenerator(context, payload){
      const headers = await store.dispatch("generateAPIHeaders");
      const fetchURL = `${LOCAL_API_BASE_URI}docgen/create/${payload.templateType}/${payload.entityId}`; 
      return await axios
        .put(fetchURL, payload, headers)
        .then(async function (response) {
          console.log("pdfGenerator",response.data);
        })
        .catch(error => {
          console.warn(error.response.data.errorMessage);
        });
    },
    async fetchFileListDataVersionTwo(context, fetchRequest) {
      var headers = {
        'Content-Type': 'application/json',
      };
      let items = null;
      let fetchURL = LOCAL_API_BASE_URI + `docgen/list/${fetchRequest.templateType}/${fetchRequest.entityPath}`
      await axios.get(fetchURL, { headers: headers })
      .then(async function (response) {
        console.log("fetchFileListDataVersionTwo",response.data.requestResponse.Contents || []);
        items = response.data.requestResponse.Contents || null;
      }).catch(function(catchError){ 
        throw new Error(catchError);
      })
      return items;
    },

    
    /* #### Cognito User Management #### */

    async getUserPermissionsList(context) {
      const fetchURL = LOCAL_API_BASE_URI + "user/permissionsList";
      const headers = await store.dispatch("generateAPIHeaders");
      await axios.get(fetchURL, headers)
      .then(async function (response) {
        context.commit("SET_USER_INFO", {
          permissions: response.data,
        });
        return;
      }).catch(error => {
        console.warn(error.response.data.errorMessage);
      });
    },

    async getUserStatus(context, entityId){
      const headers = await store.dispatch("generateAPIHeaders");
      const fetchURL = `${LOCAL_API_BASE_URI}user/status/${entityId}`; 
      return await axios
        .get(fetchURL, headers)
        .then(async function (response) {
          return response.data;
        })
        .catch(error => {
          console.warn(error.response.data.errorMessage);
        });
    },

    async changeUserStatus(context, entityId){
      const headers = await store.dispatch("generateAPIHeaders");
      const fetchURL = `${LOCAL_API_BASE_URI}user/status/${entityId}`;
      return await axios
      .put(fetchURL, null, headers)
        .then(async function (response) {
          return response.data;
        })
        .catch(error => {
          console.warn(error.response.data.errorMessage);
        });
    },



    async entityGlobalRead(context, request){
      // console.log("request",request)
      if(request.currentEntity){
        context.dispatch("setCurrentEntityLoaded", false);
      }
      const headers = await store.dispatch("generateAPIHeaders");
      const fetchURL = `${LOCAL_API_BASE_URI}${request.entityType}/${request.entityID}`; 
      if (context.state.currentFetch.includes(request.entityID)) {
        return;
      }
      context.state.currentFetch.push(request.entityID);
      return await axios
        .get(fetchURL, headers)
        .then(async function (response) {
          console.log("response.data",response.data);
          await context.commit("ADD_DOCUMENT_TO_STORE", response.data);
          context.state.currentFetch = context.state.currentFetch.filter(id => id !== request.entityID);
          if(request.currentEntity){
            context.dispatch("setCurrentEntityLoaded", true);
          }
        })
        .catch(error => {
          console.warn(error.response.data.errorMessage);
        });
    },
    async entityGlobalList(context, request){
      // console.log("request",request)
      const headers = await store.dispatch("generateAPIHeaders");
      const fetchURL = `${LOCAL_API_BASE_URI}list/${request.entityType}/${request.lookupValue}`; 
      if (context.state.currentFetch.includes(request.lookupValue)) {
        return;
      }
      context.state.currentFetch.push(request.entityType+"."+request.lookupValue);
      return await axios
        .get(fetchURL, headers)
        .then(async function (response) {
          console.log("response.data",response.data)
          await context.commit("ADD_DOCUMENT_TO_STORE", response.data);
          context.state.currentFetch = context.state.currentFetch.filter(id => id !== request.lookupValue);
        })
        .catch(error => {
          console.warn(error.response.data.errorMessage);
        });
    },
    // async entityGlobalCreate(context, payload){
    //   console.log("payload",payload)
    //   const route = payload.route;
    //   const headers = await store.dispatch("generateAPIHeaders");
    //   const fetchURL = `${LOCAL_API_BASE_URI}${route.meta.entityTypeToCreate}/${route.params.parent_id}`; 
    //   delete payload.route;
    //   return await axios
    //     .post(fetchURL, payload, headers)
    //     .then(async function (response) {
    //       console.log("response",response.data);
    //       return response.data;
    //     })
    //     .catch(function (catchError) {
    //       throw new Error(catchError);
    //     });
    // },
    async entityGlobalCreateWithoutRoute(context, payload){
      console.log("payload1",payload)
      const entityTypeToCreate = payload.routing.entityTypeToCreate;
      const parentId = payload.routing.parentId;
      delete payload.routing;
      const headers = await store.dispatch("generateAPIHeaders");
      const fetchURL = `${LOCAL_API_BASE_URI}${entityTypeToCreate}/${parentId}`; 
      return await axios
        .post(fetchURL, payload, headers)
        .then(async function (response) {
          console.log("response",response.data);
          return response.data;
        })
        .catch(error => {
          console.warn(error.response.data.errorMessage);
        });
    },
    async entityGlobalUpdateWithoutRoute(context, payload){
      console.log("payload2",payload)
      const entityTypeToUpdate = payload.routing.entityTypeToUpdate;
      const entityId = payload.routing.entityId;
      delete payload.routing;
      const headers = await store.dispatch("generateAPIHeaders");
      const fetchURL = `${LOCAL_API_BASE_URI}${entityTypeToUpdate}/${entityId}`; 
      const entityDoc = context.getters.getEntityDocument(entityId);
      payload.updated = entityDoc.updated;
      return await axios
        .put(fetchURL, payload, headers)
        .then(async function (response) {
          console.log("response",response.data);
          return response.data;
        })
        .catch(error => {
          console.warn(error.response.data.errorMessage);
        });
    },
    // async entityGlobalUpdate(context, payload){
    //   console.log("payload",payload)
    //   const route = payload.route;
    //   const headers = await store.dispatch("generateAPIHeaders");
    //   const fetchURL = `${LOCAL_API_BASE_URI}${route.meta.entityTypeToFetch}/${route.params.entity_id}`; 
    //   delete payload.route;
    //   const entityObject = context.getters.getEntityDocument(route.params.entity_id);
    //   payload.updated = entityObject.updated;
    //   return await axios
    //     .put(fetchURL, payload, headers)
    //     .then(async function (response) {
    //       console.log("response",response.data);
    //       return response.data;
    //     })
    //     .catch(function (catchError) {
    //       throw new Error(catchError);
    //     });
    // },











    async getElementByID(context, id) {
      // styledLog.store("getElementByID");
      if(id == null){
        // styledLog.error("No entityID provided", getCurrentLine());
        return null;
      }

      // eslint-disable-next-line
      let final;
      
      let entity = context.getters.getEntityByID(id);
      
      if (JSON.stringify(entity) != "{}" && entity.name !== null) {
        return entity;
      } else {
        let fetchURL = `${LOCAL_API_BASE_URI}item/${id}`; 

        // styledLog.external("axios.post " + fetchURL, getCurrentLine());


        let loadingID = Math.random().toString(36).substring(2) + Date.now().toString(36);
        context.commit("WAITING_FOR_DATA", loadingID);
        const headers = await store.dispatch("generateAPIHeaders");
        return await axios
          .get(fetchURL, headers)
          .then(async function (response) {
            final = response.data.payload;

            let entity = {      
              id: final.entity._id,    
              //name: final.entity.name,
              display: final.entity.name,
              type: final.entityType,
              entityObj: final.entity,
              path: final.rootPath,
              navPath: final.navPath,
            }
            //console.log(entity)
            await context.commit("UPDATE_PATH_BY_ID", entity);
            await context.commit("DONE_WAITING_FOR_DATA", loadingID);
            
            return entity;
          });
      }

    },
    async generateCalulations(context, payload) {
      // styledLog.store("generateCalulations");
      if(payload == null){
        // styledLog.error("No entityID provided", getCurrentLine());
        return null;
      }

      // eslint-disable-next-line
      let fetchURL = `${LOCAL_API_BASE_URI}calculation/totals`; 

      // styledLog.external("axios.post " + fetchURL, getCurrentLine());
      // let payload = {
      //   entityID: transit.id
      // };
      //let loadingID = Math.random().toString(36).substring(2) + Date.now().toString(36);
      //context.commit("WAITING_FOR_DATA", loadingID);
      const headers = await store.dispatch("generateAPIHeaders");
      return await axios
        .post(fetchURL, {"id":payload.entityID}, headers)
        .then(async function (response) {
          const calculations = response.data;
          console.log("calculations",calculations)
        });
      

    },
    async parseSourceofTruth(context) {
      const sourceOfTruth = context.state.sourceOfTruth;
      await context.dispatch('recurseNodes', { payload: sourceOfTruth, path: "", ancestry:[]});

      await context.dispatch('loopTypes', { type: 'contact', entity: sourceOfTruth.contractor_contact } );
      await context.dispatch('loopTypes', { type: 'contact', entity: sourceOfTruth.owner_contact } );
      await context.dispatch('loopTypes', { type: 'location', entity: sourceOfTruth.location } );
      await context.dispatch('loopTypes', { type: 'unit_price_book', entity: sourceOfTruth.unit_price_book } );
    },
    async appGetListData(context, entity) {
      const headers = await store.dispatch("generateAPIHeaders");
      let entityObj = {};
      
      // Used to hot-swap between which Lambda is being targeted
      let fetchURL = `${LOCAL_API_BASE_URI}${entity.relatedType}/list`; 

      let payload = {
        parentID: entity.parentID,
        parentType: entity.parentType,
      };

      // styledLog.external("axios.post " + fetchURL);
      let loadingID = Math.random().toString(36).substring(2) + Date.now().toString(36);
      
      // context.commit("WAITING_FOR_DATA", loadingID);

      let l = await axios
        .post(fetchURL, payload, headers)
        .then(async function (response) {
          if(!response.data.payload.list){
            return;
          }

          response.data.payload.list.map((item) => {  
            item.id   = item._id;
            item.key  = item._id;
            entityObj[item._id] = item;
          });

          let payload = response.data.payload;
          
          //set each item individually to the dom
          payload.list.forEach((item) => {
            
            let name;
            if(item.first_name)
              name = `${item.first_name} ${item.last_name}`;
            else if (item.name)
              name = `${item.name}`;
            else if (item.display)
              name = `${item.display}`;
            else
              name = `${item.type}`;

            let entity = {      
              id: item.id,    
              display: name,
              type: payload.type,
              entityObj: item,
              path: `${payload.path}.${item.id}`,
              navPath: `${payload.navpath}.${item.id}`,
            }

            context.commit("UPDATE_PATH_BY_ID", entity);
          })

          //eslint-disable-next-line
          let nodes = {
            parentID: entity.parentID,
            type: entity.relatedType,
            targetType: entity.relatedType,
            path: response.data.payload?.path,
            list: entityObj
          }
          
          await context.commit("ADD_LIST_TO_STORE", nodes);
          //await context.dispatch("parseSourceofTruth");

          context.commit("ADD_APP_INFO", response.data.appInfo);
          // if (response.data.appInfo.success) {
          //   styledLog.success(response.data.appInfo.primaryMessage);
          // } else {
          //   styledLog.error(response.data.appInfo.primaryMessage);
          // }

          return entityObj;
        })
        .catch(function (catchError) {
          throw new Error(catchError);
        });

      //store.state.eventBus.emit("FINISHED_LOAD_APPDATA"); // SUKER look here for the BUS
      // context.commit("DONE_WAITING_FOR_DATA", loadingID);
      context.commit("DONE_WAITING_FOR_APPDATA", loadingID);
      
      return l;
    },   
    async appGetPageData(context, item) {
      // styledLog.store("appGetPageData");
      let entityID;
      let vueBus;
      
      if(item == null){
        // styledLog.error("No entityID provided", getCurrentLine());
        return 
      } else {
        entityID = item.entityID;
        vueBus = item.vueBus;
       }

       const headers = await store.dispatch("generateAPIHeaders");
      
      // Used to hot-swap between which Lambda is being targeted
      // eslint-disable-next-line
      // let fetchURL = API_BASE_URI + "init/" + context.getters.getAuthInfo("role");
      // eslint-disable-next-line

      let fetchURL = `${LOCAL_API_BASE_URI}entity/page`; 

      let nodes;

      // styledLog.external("axios.post " + fetchURL, getCurrentLine());
      let payload = {
        entityID: entityID
      };
      let loadingID = Math.random().toString(36).substring(2) + Date.now().toString(36);
      context.commit("WAITING_FOR_DATA", loadingID);
      
      
      await axios
        .post(fetchURL, payload, headers)
        .then(async function (response) {
          
          nodes = response.data.payload

          await context.commit("ADD_INIT_TO_STORE", nodes);
          await context.dispatch("parseSourceofTruth");

          await context.commit("ADD_APP_INFO", response.data.appInfo);

          // if (response.data.appInfo.success) {
          //   styledLog.success(response.data.appInfo.primaryMessage, getCurrentLine());
          // } else {
          //   styledLog.error(response.data.appInfo.primaryMessage, getCurrentLine());
          // }
        })
        .catch(function (catchError) {
          throw new Error(catchError);
        });

      context.commit("DONE_WAITING_FOR_DATA", loadingID);
      context.commit("DONE_WAITING_FOR_APPDATA", loadingID);
      
      vueBus.emit("FINISHED_LOAD_APPDATA"); // SUKER look here for the BUS
      return nodes;
    },
    async loopTypes(context, payload){

      Object.values(payload.entity).forEach(async (item) => {

        let parent = context.state.entity[item.parent_id];
        console.log(parent)

        if(parent){
          let navPath = `${parent.path}.${payload.type}.${item._id}`;
          await context.commit("UPDATE_NAVPATH_BY_ID", {id: item._id, navPath: navPath});  
        }
      })
    },
    async recurseNodes( context, node){

      //each recursion requires two passes
      let node_list = node.payload;
      let ancestry = node.ancestry;

      //first pass is the object type
      for(let node_type in node_list){

        //is it an object, yes, let's do this, no? let's skip
        if(Object.prototype.toString.call(node_list[node_type]) === '[object Object]'){

          //this loop will be through child level IDs
          for(let child_id in node_list[node_type]){

            let child_item = node_list[node_type][child_id];
            let object_path = `${node.path}.${node_type}.${child_id}`
            let type_name = await context.dispatch('getName', child_item);

            switch (node_type) {
              case "address":
              case "metadata":
                continue;
              default:
                await context.commit("UPDATE_PATH_BY_ID", {
                  type: node_type, 
                  id: child_id, 
                  path: object_path.substring(1), 
                  entityObj: child_item, 
                  display: type_name,
                  //name: type_name
                });
            }


            //loop child_item to see what needs recursion
            for(let props in child_item){

              //check if object AND has an _id
              if(Object.prototype.toString.call(child_item[props]) == '[object Object]' ){
                let base_ancestry = [];
                if( !(
                    node_type == "metadata" &&
                    node_type == "address" &&
                    node_type == "config" )
                ){
                    //clone the ancestry
                    base_ancestry = JSON.parse(JSON.stringify(ancestry));

                    //add current node to excluded it in the ancestry, move it below the next line
                    base_ancestry.push({entity_type: node_type, entity_id: child_id, name: type_name})

                    //grip it and rip it.
                    await context.dispatch('recurseNodes', { payload: child_item, path: object_path, ancestry: base_ancestry});
                }
              }
            }
          }
        }
      }
    },
    getName(context, entity){
      let type_name = "";
      if(entity && entity.name ){
        type_name = entity.name
      } else if (entity && entity.first_name){
        type_name = `${entity.first_name} ${entity.last_name}`
      }
      return type_name;
    },
    async logUserOut(context) {
      // styledLog.store("logUserOut", getCurrentLine());
      await signOut();
      router.push({ path: "/signin" });
      context.commit("DELETE_STATE");
    },
    /**
     *
     * @param {object} submitData.lookupInfo - Provides all of the context needed to make the request.
     * @param {object} submitData.payload - The payload object to send to the API. This is the actual data that we want to add or update.
     * @returns returnID - The ID of the entity that was created
     */
    
    async appEntitySaveAndNav(context, submitData){
      let id = await context.dispatch("appEntitySave", submitData);
      submitData.navID = id;
      await context.dispatch("appEntityNav", submitData);
    },

    async appEntityNav(context, submitData){
      let props = {
        path: "/" + submitData.lookupInfo.entityType + "/" + submitData.navID + "/read",
      };
      router.push(props);

    },
    async getSelectedProposal(context, work_order_id){
      let param = {
        parentID: work_order_id,
        parentType: "work_order",
        relatedType: "proposal",
      };

      let list = await store.dispatch("appGetListData", param);
      
      for(var item in list){
        console.log(list[item])
        if(list[item].status=="approved"){
          return list[item]
        }
      }
      return null;
    },

    async appEntitySave(context, submitData) {
      // styledLog.store("appEntityCreateAndUpdate");
      let returnID = null;

      console.log("submitData", submitData);

      const headers = await store.dispatch("generateAPIHeaders");
      let requestType = submitData.lookupInfo.requestType;
      let endpoint = LOCAL_API_BASE_URI + "entity/" + requestType + "FromSnap";

      //TODO: we need a better way to write this. Probably a method, and shouldn't have magic numbers without comments
      let loadingID =
        Math.random().toString(36).substring(2) + Date.now().toString(36);
      context.commit("WAITING_FOR_DATA", loadingID);

      // styledLog.external("axios.post " + endpoint, getCurrentLine());
      await axios
        .post(endpoint, submitData, headers)
        .then(async function (response) {
          console.log("response", response);
          context.commit("ADD_APP_INFO", response.data.appInfo);
          if (response.data.appInfo.success) {
            // styledLog.success(response.data.appInfo.primaryMessage, getCurrentLine());
            await context.commit("MERGE_NEW_DATA", response.data.payload);
            
            
            await context.dispatch("parseSourceofTruth");
            returnID = response.data.appInfo.returnID;

            // let props = {
            //   path: "/" + submitData.lookupInfo.entityType + "/" + returnID + "/read",
            // };
            // router.push(props);

          } else {
            // styledLog.error(response.data.appInfo.primaryMessage, getCurrentLine());
            // If there is a failed MongoDB validation, the error message gets returned in the response payload now. 
            // Using the if statement here, it can be determined that the error message came through.
            // This parses through the error message and creates a snackbar message that lists out the reasons for failures
            // The reasons for failures are the descriptions of each property that failed validation
            if (response.data.payload?.errInfo?.details?.schemaRulesNotSatisfied[0]?.propertiesNotSatisfied){
              const responseArray = response.data.payload.errInfo.details.schemaRulesNotSatisfied[0].propertiesNotSatisfied;

              let notice = 'Document Validation Failed:';
              responseArray.forEach((entry) => {
                notice = notice.concat(`<br> - ${entry.description}`);
              });
              const appNotice ={
                message: notice,
                success: false
              }
              store.dispatch('createAppNotice', appNotice);
            }
          }
        })
        .catch(function (catchError) {
          let errorNotice = {
            primaryMessage: catchError,
            success: false,
          };
          context.commit("ADD_APP_INFO", errorNotice);
          throw new Error(catchError);
        });
      context.commit("DONE_WAITING_FOR_DATA", loadingID);
      return returnID;
    },

    async appUserCreate(context, payload) {
      // styledLog.store("appUserCreate");
      const headers = await store.dispatch("generateAPIHeaders");

      let apiFullUri = LOCAL_API_BASE_URI + "user/create";
      let loadingID =
        Math.random().toString(36).substring(2) + Date.now().toString(36);
      context.commit("WAITING_FOR_DATA", loadingID);
      // styledLog.external("axios.post " + apiFullUri, getCurrentLine());
      await axios
        .post(apiFullUri, payload, headers)
        .then(async function (response) {
          context.commit("ADD_APP_INFO", response.data.appInfo);
          if (response.data.appInfo.success) {
            context.commit("SPREAD_ENTITY_TO_STATE", response.data.payload);
            // styledLog.success(response.data.appInfo.primaryMessage, getCurrentLine());
          } else {
            // styledLog.error(response.data.appInfo.primaryMessage, getCurrentLine());
          }
        })
        .catch(function (catchError) {
          let errorNotice = {
            primaryMessage: catchError,
            success: false,
          };
          context.commit("ADD_APP_INFO", errorNotice);
          throw new Error(catchError);
        });
      context.commit("DONE_WAITING_FOR_DATA", loadingID);
    },
    async appUserDemote(context, payload) {
      // styledLog.store("appUserDemote");
      const headers = await store.dispatch("generateAPIHeaders");

      let apiFullUri = LOCAL_API_BASE_URI + "user/demote";
      let loadingID =
        Math.random().toString(36).substring(2) + Date.now().toString(36);
      context.commit("WAITING_FOR_DATA", loadingID);
      // styledLog.external("axios.post " + apiFullUri, getCurrentLine());
      await axios
        .post(apiFullUri, payload, headers)
        .then(async function (response) {
          context.commit("ADD_APP_INFO", response.data.appInfo);
          if (response.data.appInfo.success) {
            context.commit("SPREAD_ENTITY_TO_STATE", response.data.payload);
            // styledLog.success(response.data.appInfo.primaryMessage, getCurrentLine());
          } else {
            // styledLog.error(response.data.appInfo.primaryMessage, getCurrentLine());
          }
        })
        .catch(function (catchError) {
          let errorNotice = {
            primaryMessage: catchError,
            success: false,
          };
          context.commit("ADD_APP_INFO", errorNotice);
          throw new Error(catchError);
        });
      context.commit("DONE_WAITING_FOR_DATA", loadingID);
    },
    async appUserPromote(context, payload) {
      // styledLog.store("appUserPromote");
      const headers = await store.dispatch("generateAPIHeaders");

      let apiFullUri = LOCAL_API_BASE_URI + "user/promote";
      let loadingID =
        Math.random().toString(36).substring(2) + Date.now().toString(36);
      context.commit("WAITING_FOR_DATA", loadingID);
      // styledLog.external("axios.post " + apiFullUri, getCurrentLine());
      await axios
        .post(apiFullUri, payload, headers)
        .then(async function (response) {
          context.commit("ADD_APP_INFO", response.data.appInfo);
          if (response.data.appInfo.success) {
            context.commit("SPREAD_ENTITY_TO_STATE", response.data.payload);
            // styledLog.success(response.data.appInfo.primaryMessage, getCurrentLine());
          } else {
            // styledLog.error(response.data.appInfo.primaryMessage, getCurrentLine());
          }
        })
        .catch(function (catchError) {
          let errorNotice = {
            primaryMessage: catchError,
            success: false,
          };
          context.commit("ADD_APP_INFO", errorNotice);
          throw new Error(catchError);
        });
      context.commit("DONE_WAITING_FOR_DATA", loadingID);
    },

    async genReport(context, fetchRequest) {
      // styledLog.store("genReport");
      var url = "";

      var headers = {
        'Content-Type': 'application/json',
        //'Authorization': store.getters.getAccesstoken
      };

      let fetchURL = LOCAL_API_BASE_URI + "docgen/create"

      // let fetchURL =
      //   "https://7hpees0a04.execute-api.us-west-1.amazonaws.com/Prod/docgen/create";
      await axios
        .post(fetchURL, fetchRequest, { headers: headers })
        .then(async function (response) {
          // Get the data from the response
          if (response.data.success) {
            await response.data.message;
            url = await response.data.message;
            //return url;
          }
        })
        .catch(function (catchError) {
          console.log(catchError);
          throw new Error(catchError);
        });

      return await url;
    },
    async fetchUploadLink(context, fetchRequest) {
      // styledLog.store("fetchUploadLink");
      var url = "";

      const headers = await store.dispatch("generateAPIHeaders");

      let fetchURL = LOCAL_API_BASE_URI + "docgen/upload"

      // let fetchURL =
      //   "https://7hpees0a04.execute-api.us-west-1.amazonaws.com/Prod/docgen/upload";
      await axios
        .post(fetchURL, fetchRequest, headers)
        .then(async function (response) {
          // Get the data from the response
          if (response.data.success) {
            await response.data.message;
            url = await response.data.message;
            //return url;
          }
        })
        .catch(function (catchError) {
          console.log(catchError);
          throw new Error(catchError);
        });

      return await url;
    },
    async fetchFileList(context, fetchRequest) {
      // styledLog.store("fetchFileList");
      let loadingID = Math.random().toString(36).substring(2) + Date.now().toString(36);
      context.commit("WAITING_FOR_DATA", loadingID);

      var list = "";

      var headers = {
        'Content-Type': 'application/json',
        //'Authorization': store.getters.getAccesstoken
      };

      let fetchURL = LOCAL_API_BASE_URI + "docgen/list_templates"
      //let fetchURL = "https://7hpees0a04.execute-api.us-west-1.amazonaws.com/Prod/docgen/list_templates"
      await axios.post(fetchURL, fetchRequest, { headers: headers })
      .then(async function (response) {
        // Get the data from the response
          await response.data.success
          list = await response.data.message.Contents;
          //return url;

      }).catch(function(catchError){ 
        console.log(catchError)
        throw new Error(catchError);
      })

      context.commit("DONE_WAITING_FOR_DATA", loadingID)
      return await list;
    },
    async fetchTemplate(context, fetchRequest) {
      // styledLog.store("fetchTemplate");
      // Create a unique ID for this loading event

      let loadingID = Math.random().toString(36).substring(2) + Date.now().toString(36);
      context.commit("WAITING_FOR_DATA", loadingID);

      var list = "";

      let headers = {
        'Content-Type': 'application/json',
        //'Authorization': store.getters.getAccesstoken
      };
      
      let fetchURL = LOCAL_API_BASE_URI + "docgen/template"
      //let fetchURL = "https://7hpees0a04.execute-api.us-west-1.amazonaws.com/Prod/docgen/template"
      await axios.post(fetchURL, fetchRequest, { headers: headers })
      .then(async function (response) {
        // Get the data from the response
          await response.data.success
          list = await response.data.message;
          //return url;

      }).catch(function(catchError){
        console.log(catchError);
        throw new Error(catchError);
      });

      context.commit("DONE_WAITING_FOR_DATA", loadingID)
      return await list;
    },
    async fetchDownload(context, fetchRequest) {
      // styledLog.store("fetchDownload");
      let headers = {
        'Content-Type': 'application/json',
        //'Authorization': store.getters.getAccesstoken
      };
      let fetchURL = LOCAL_API_BASE_URI + "docgen/download"
      
      await axios.post(fetchURL, fetchRequest, { headers: headers })
      .then(async function (response) {
        // Get the data from the response
        console.log(response)

        window.open(response.data.message)
        console.log(response)
      }).catch(function(catchError){
        console.log(catchError);
        throw new Error(catchError);
      });
    },
    // eslint-disable-next-line
    async deleteDocument(context, documentKey){
      const headers = await store.dispatch("generateAPIHeaders");
      const encodedURL = encodeURIComponent(documentKey);
      const fetchURL = `${LOCAL_API_BASE_URI}docgen/delete?file=${encodedURL}`;
      return await axios
        .delete(fetchURL, headers)
        .then(async function () {
          return true;
        })
        .catch(error => {
          console.log("pdfGenerator",error.response.data);
          console.warn(error.response.data.errorMessage);
        });
    },
    async postFile(context, fetchRequest) {
      // styledLog.store("postFile");
      var headers = {
        'Content-Type': 'text/plain',
        'Authorization': store.getters.getAccesstoken
      };

      return await axios.put(fetchRequest.url, fetchRequest.file, headers)
      .then(async function (response) {
        // Get the data from the response
        if(response.data.success){
          return response.data.message;
        }
      }).catch(function(catchError){ 
        console.log(catchError)
        throw new Error(catchError);
      })
    },

    

    async isUserLoggedIn() {
      try{
        await getCurrentUser();
        return true;
      }catch(error){
        return false
      }
    },

    async isIdTokenValid(context){
      const tokenExpiration = context.state.authInfo.idToken.exp;
      const checkAgainstTime = Math.floor(new Date().getTime() / 1000) + 30;
      return tokenExpiration > checkAgainstTime ? true : false;
    },

    async fetchAndStoreIdToken(context) {
      try {
        const authSession = await fetchAuthSession();
        context.commit("SET_USER_INFO", {
          authJWT: authSession.tokens.idToken.toString(),
          idToken: authSession.tokens.idToken.payload,
        });
      } catch (error) {
        throw new Error(error);
      }
    },

    async setupUser(context){
      await context.dispatch("fetchAndStoreIdToken");
      await context.dispatch("getUserPermissionsList");
    },

    async generateAPIHeaders(context){
      if(!await context.dispatch("isIdTokenValid")){
        await context.dispatch("fetchAndStoreIdToken");
      }
      return {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': context.state.authInfo.authJWT
        }
      };
    },
    
    createAppNotice(context, notice) {
      context.commit("CREATE_APP_NOTICE", notice);
    },
    async logBugToDB(context, payload) {
      const headers = await store.dispatch("generateAPIHeaders");
      let apiFullUri = LOCAL_API_BASE_URI + "logbug";
      await axios
        .post(apiFullUri, payload, headers)
        .then(async function (response) {
          context.commit("ADD_APP_INFO", response.data.appInfo);
        })
        .catch(function (catchError) {
          let errorNotice = {
            primaryMessage: catchError,
            success: false,
          };

          context.commit("ADD_APP_INFO", errorNotice);
          throw new Error(catchError);
        });
    },
  },
  mutations: {
    async ADD_DOCUMENT_TO_STORE(state, payload) {
      if(!payload.documentArray){
        throw new Error("No data returned from API.");
      }
      payload.documentArray.forEach(document => {
        const documentID = document._id;
        const segments = document._path.split('.');
        const entityType = segments[segments.length - 2];
        document._type = entityType;
        if(document?.core?.name){
          document._name = document?.core?.name;
        }else if(document?.core?.first_name){
          document._name = document?.core?.first_name + " " + document?.core?.last_name;
        }
        state.documents[documentID] = document;
      });
    },
    ADD_LIST_TO_STORE(state, listData) {

      let element;
      switch(listData.type){
        //these are all top level entities
        case "contractor":
        case "unit_price_book":
        case "location":
        case "owner_contact":
        case "contractor_contact":
        case "owner":
          //TODO: this should be addititive and not replacing
          state.sourceOfTruth[listData.type] = listData.list;

          //loop items and add individually
          break;
        default:
          element = this.getters.getEntityByID(listData.parentID);   

          //TODO: this should be addititive and not replacing
          if(Object.keys(element).length !== 0){
            element.entityObj[listData.type] = listData.list;
          } else {
            let entityObj = {};
            entityObj[listData.targetType] = listData.list;
            
            //create parent object as entity in store
            let element = {
              type: listData.type, 
              parent_id: listData.parentID, 
              path: listData.path, 
              entityObj: entityObj,
              display: listData.type
            }

            //add entity to store
            state.entity[listData.parentID] = {};
            state.entity[listData.parentID][listData.type] = element;

          }
      }

    },
    ADD_INIT_TO_STORE(state, payload) {
      state.sourceOfTruth = payload;
    },
    MERGE_NEW_DATA(state, payload) {
      state.sourceOfTruth = lodash.merge({}, state.sourceOfTruth, payload);
    },
    ADD_APP_INFO(state, appInfo) {

      state.lambdaLogs.push(appInfo.lambdaLog);
      state.appNotice.message = appInfo.primaryMessage;
      state.appNotice.color = appInfo.success ? "green" : "red";
      state.appNotice.show = true;
      state.appNotice.timeout = appInfo.success ? 2000 : 5000;
      if (appInfo.catchError) {
        console.log("appInfo.catchError", appInfo.catchError);
      }
      if(appInfo.error){
        let errorMessage = "";
        const errors = appInfo.error.errInfo.details.schemaRulesNotSatisfied[0].propertiesNotSatisfied;
        errors.forEach((error) => {
          const details = error.details[0];
          const capitalized = error.propertyName.charAt(0).toUpperCase() + error.propertyName.slice(1);
          if(details.consideredType === "null" && details.consideredValue === null){
            errorMessage += `${capitalized} is a required field.<br/>`;
          }
          if(details.operatorName === "minLength"){
            errorMessage += `${capitalized} must be at least ${details.specifiedAs.minLength} characters.<br/>`;
          }
          if(details.operatorName === "maxLength"){
            errorMessage += `${capitalized} must be no longer than ${details.specifiedAs.maxLength} characters.<br/>`;
          }
          console.log("error",error);
        });
        state.appNotice.message = errorMessage;
      }
    },
    UPDATE_PATH_BY_ID(state, payload) {
      //thiis gets called on proposal over and over again, which is werid
      
      //building a gate to prevent the update race condition
      if(!state.entity?.type ){
        let entity = {
          name:       payload.name?payload.name:payload.display,
          type:       payload.type,
          entityObj:  payload.entityObj,
          path:       payload.path,
          navPath:    payload.path,
        };
        state.entity[payload.id] = entity  
      }

    },
    UPDATE_NAVPATH_BY_ID(state, payload){
      state.entity[payload.id].navPath = payload.navPath;
    },
    CREATE_APP_NOTICE(state, appNotice) {
      state.appNotice.message = appNotice.message;
      state.appNotice.color = appNotice.success ? "green" : "red";
      state.appNotice.show = true;
      state.appNotice.timeout = appNotice.success ? 2000 : 5000;
    },
    WAITING_FOR_DATA(state, loadingID) {
      state.itemsBeingLoaded.push(loadingID)
      return 'something'
    },
    WAITING_FOR_APPDATA(state, loadingID) {
      state.itemsBeingLoaded.push(loadingID)
      return 'something'
    },
    DONE_WAITING_FOR_APPDATA(state) {
      state.pageLoads.all = true;
    },
    DONE_WAITING_FOR_DATA(state, loadingID) {
      state.itemsBeingLoaded = state.itemsBeingLoaded.filter(
        (item) => item !== loadingID
      );
    },
    SET_USER_INFO(state, authObject) {
      state.authInfo = {
        ...state.authInfo,
        ...authObject
      };    
    },
    SET_EVENTBUS(state, eventBus) {
      state.eventBus = eventBus;
    },
    SET_APP(state, app) {
      state.app = app;
    },
    DELETE_STATE() {
      store.replaceState(getDefaultState());
      //var m = mitt();
      
      //reset connection to new mitt.
      //store.app.config.globalProperties.$eventBus = m;
      //store.commit('SET_EVENTBUS', m);    
    },
  },
});

export default store;