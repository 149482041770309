<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h1>All Owners</h1>
            </v-col>
            <v-col class="d-flex ga-4 justify-end">
                <v-btn color="primary" variant="outlined" @click="navigateToOwnerCreate()">
                    Create Owner
                </v-btn>
            </v-col>
        </v-row>

        <!-- <TableList
            :current="current"
            :details="clientContactsDetails"
        /> -->

        <v-row>
            <v-col>
                <v-data-table
                    :headers="ownersListHeaders"
                    :items="ownersList"
                    item-key="_id"
                    :items-per-page="100"
                    @click:row="navigateToOwnerRead"
                    :loading="isLoading.ownersList"
                    loading-text="Loading owners list..."
                    disable-sort
                    >
                </v-data-table>
            </v-col>
        </v-row>

    </v-container>
</template>
<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { reactive, onBeforeMount, computed } from 'vue';
const store = useStore();
const router = useRouter();
const isLoading = reactive({});


// const current = {
//     type: "owner",
//     all: true,
// };

// const clientContactsDetails = {
//     listType: "owner",
//     heading: "Client Contacts",
//     tableHeaders: [
//         {
//             title: "ID",
//             key: "_id",
//         }
//     ],
// };

const authInfo = store.state.authInfo.idToken;
if(authInfo["custom:appRole"] === "owner_viewer"){
    router.push(`/owner/${authInfo["custom:accessId"]}`); 
}




const ownersList = computed(() => {
    return store.getters.getChildList("owner.", "owner");
})


onBeforeMount(() => {
    fetchOwnersList();
})

async function fetchOwnersList() {
    isLoading.ownersList = true;
    await store.dispatch("entityGlobalList", {
        entityType: "owner",
        lookupValue: "all",
    });
    isLoading.ownersList = false;
}




function navigateToOwnerRead(item, row){
    router.push(`/owner/${row.item._id}`);
}
function navigateToOwnerCreate(){
    router.push(`/owner/create`);
}

const ownersListHeaders = [
    {
        title: "Name",
        key: "core.name",
    },
    {
        title: "Email",
        key: "core.email",
    },
    {
        title: "Phone",
        key: "core.phone",
    },
    // {
    //     title: "ID",
    //     key: "_id",
    // }
];
</script>