<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h1>Contractor List</h1>
            </v-col>
            <v-col class="d-flex ga-4 justify-end">
                <v-btn color="primary" variant="outlined" @click="createContractor()" v-if="$hasPermission(`contractor:create`)">
                    Create Contractor
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-data-table
                    :headers="contractorTableHeaders"
                    :items="contractors"
                    item-key="_id"
                    :items-per-page="100"
                    @click:row="tableRowClick"
                    :loading="!loadedData.contractors"
                    loading-text="Loading contractors..."
                    disable-sort
                    >
                </v-data-table>
            </v-col>
        </v-row>

    </v-container>
</template>
<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { reactive, onBeforeMount, computed } from 'vue';
const store = useStore();
const router = useRouter();
const loadedData = reactive({
    contractors: false,
});
const contractorTableHeaders = [
    {
        title: "Name",
        key: "core.name",
    },
    // {
    //     title: "ID",
    //     key: "_id",
    // }
];



onBeforeMount(() => {
    fetchContractors();
})

async function fetchContractors() {
    await store.dispatch("entityGlobalList", {
        entityType: "contractor",
        lookupValue: "all",
    });
    loadedData.contractors = true;
}

// function pathFilter(doc){
//     if(doc._path && doc._path === "contractor."+doc._id){
//         return true;
//     }
//     return false;
// }
const contractors = computed(() => {
    return store.getters.getChildList("contractor.", "contractor");
})




function tableRowClick(item, row){
    router.push(`/contractor/${row.item._id}`);
}
function createContractor(){
    router.push(`/contractor/create`);
}
</script>