<template>
    <v-container fluid>
        <ReadHeader :current="current" />
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`JOC Project Number`"
                :value="entityCore.joc_project_number"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Estimate`"
                :value="entityCore.estimate"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`LACCD Project Number`"
                :value="entityCore.laccd_project_number"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Financial ID`"
                :value="entityCore.financial_id"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`Project Type`"
                :value="entityCore.project_type"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Project Status`"
                :value="entityCore.project_status"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`Location`"
                :value="nameById(entityCore.location_uid)"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Client`"
                :value="nameById(entityCore.client_uid)"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`Client Contact`"
                :value="nameById(entityCore.client_contact_uid)"
                ></KeyValue>
            </v-col>
            <v-col>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`Brief Scope`"
                :value="entityCore.brief_scope"
                ></KeyValue>
            </v-col>
        </v-row>

        <TableList
            :current="current"
            :details="workOrdersDetails"
        />

    </v-container>
</template>
<script setup>
import { useRoute } from 'vue-router';
import { reactive, watch } from 'vue';
import KeyValue from "@/components/interface/Key-Value.vue";
import ReadHeader from "@/components/interface/ReadHeader.vue";
import TableList from "@/components/interface/TableList.vue";
import { useNameById, useFetchSingle, useIsCurrentEntityLoaded, useEntityCore } from "@/assets/modules/composables.js";


const route = useRoute();
const isLoading = reactive({});
const alreadyFetchedData = [];
const { nameById } = useNameById();
const { fetchSingle } = useFetchSingle(isLoading, alreadyFetchedData);
const { isCurrentEntityLoaded } = useIsCurrentEntityLoaded();
const { entityCore } = useEntityCore();

const current = {
    type: "project",
    id: route.params.entity_id,
};

const workOrdersDetails = {
    listType: "work_order",
    heading: "Job Orders",
    tableHeaders: [
        // {
        //     title: "ID",
        //     key: "_id",
        // }
    ],
};


watch(isCurrentEntityLoaded, async () => {
    if(isCurrentEntityLoaded.value){
        fetchSingle("location", entityCore.value.location_uid);
        fetchSingle("client", entityCore.value.client_uid);
        fetchSingle("client_contact", entityCore.value.client_contact_uid);
    }
})
</script>