<template>
    <v-container fluid>
        <ReadHeader :current="current" />

        <v-row>
            <v-col>
                <KeyValue
                :title="`Status`"
                :value="getTitleFromValue(entityCore.status)"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Unit Price Book`"
                :value="nameById(entityCore.unit_price_book_uid)"
                ></KeyValue>
            </v-col>
        </v-row>

        <v-row><v-col><v-divider></v-divider></v-col></v-row>

            <v-row>
                <v-col>
                    <h3>Subcontractors</h3>
                </v-col>
            </v-row>

        <v-row>
            <v-col>
                <v-data-table
                    :headers="subcontractorsListHeaders"
                    :items="entityCore.subcontractors"
                    item-key="_id"
                    :items-per-page="100"
                    :hide-default-footer="true"
                    disable-sort
                    >
                </v-data-table>
            </v-col>
        </v-row>
        
        <template v-if="$hasPermission(`proposal:create`)">
            <v-row><v-col><v-divider></v-divider></v-col></v-row>

                <v-row>
                    <v-col>
                        <h3>External Estimates</h3>
                    </v-col>
                </v-row>

            <v-row>
                <v-col>
                    <v-data-table
                        :headers="externalEstimatesListHeaders"
                        :items="entityCore.external_estimates"
                        item-key="_id"
                        :items-per-page="100"
                        :hide-default-footer="true"
                        disable-sort
                        >
                    </v-data-table>
                </v-col>
            </v-row>
        </template>


        <v-row><v-col><v-divider></v-divider></v-col></v-row>

        <v-row>
            <v-col>
                <h3>Proposal Detail</h3>
            </v-col>
            <v-col class="d-flex ga-4 justify-end" v-if="$hasPermission(`document:generate`)">
                <v-btn color="primary" variant="outlined" @click="generateProposalDetail()">
                    Generate
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-data-table
                    :headers="tableHeaders"
                    :items="proposalDetailFileList"
                    :loading="proposalDetailFileListLoading"
                    loading-text="Loading..."
                    item-value="Key"
                    :items-per-page="100"
                >
                
                
                <template v-slot:[`item.Key`]="{ item }">
                {{ getFileName(item.Key) }}
                </template>
                <template v-slot:[`item.Size`]="{ item }">
                {{ bytesToSize(item.Size) }}
                </template>

                <template v-slot:[`item.LastModified`]="{ item }">
                    <span v-html="getCreatedDate(item.LastModified)"></span>
                </template>

                <template v-slot:[`item.Action`]="{ item }">
                <v-btn
                    variant="plain"
                    icon="mdi-eye"
                    @click.stop="download(item.Key)"
                    size="small"
                    title="Download"
                ></v-btn>

                </template>
                </v-data-table>
            </v-col>
        </v-row>
        
    </v-container>
</template>
<script setup>
import { reactive, watch, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useEntityCore, useNameById, useIsCurrentEntityLoaded, useFetchSingle } from "@/assets/modules/composables.js";
import ReadHeader from "@/components/interface/ReadHeader.vue";
import KeyValue from "@/components/interface/Key-Value.vue";
import statusesList from "@/assets/selects/project-work-order-proposal-status.json";

const route = useRoute();
const { nameById } = useNameById();
const { entityCore } = useEntityCore();
const isLoading = reactive({});
const alreadyFetchedData = [];
const { isCurrentEntityLoaded } = useIsCurrentEntityLoaded();
const { fetchSingle } = useFetchSingle(isLoading, alreadyFetchedData);
const store = useStore();

const current = {
    type: "proposal",
    id: route.params.entity_id,
};
function getTitleFromValue(value) {
    const item = statusesList.find(item => item.value === value);
    return item ? item.title : value;
}

const proposalDoc = computed(() => {
    return store.getters.getEntityDocument(current.id);
});

const proposalDetailFileList = ref([]);
const proposalDetailFileListLoading = ref(true);
watch(isCurrentEntityLoaded, async () => {
    if(isCurrentEntityLoaded.value){
        console.log("entityCore", entityCore.value)
        fetchSingle("unit_price_book", entityCore.value.unit_price_book_uid);
        if(entityCore.value?.subcontractors){
            entityCore.value.subcontractors.forEach((subcontractor) => {
                fetchSingle("subcontractor", subcontractor.subcontractor_uid);
            });
        }
        console.log("proposalDoc",proposalDoc.value)

        proposalDetailFileList.value = await getProposalDetailList();
        proposalDetailFileListLoading.value = false;
    }
})
async function getProposalDetailList(){
    const entityPath = store.getters.getEntityDocument(current.id)?._path
    const items = await store.dispatch("fetchFileListDataVersionTwo", {
        templateType: "proposal-detail",
        entityPath: entityPath,
    });

    return items || [];
}
function generateProposalDetail(){
    store.dispatch("pdfGenerator", {
        templateType: "proposal-detail",
        entityId: current.id,
    });
}
function bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return 'n/a'
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
    if (i === 0) return `${bytes} ${sizes[i]})`
    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
}
function getCreatedDate(dateStr){
    const date = new Date(dateStr);
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
    };
    const formattedDate = date.toLocaleString('en-US', options);
    const [datePart, timePart] = formattedDate.split(', ');
    return `${datePart}<br/><span class="created-date">${timePart}</span>`;
}
function getFileName(Key){
    const parts = Key.split("/");
    return parts[parts.length-1];
}
async function download(Key){
    var loadPayload = {
        Key: Key
    };

    await store.dispatch("fetchDownload", loadPayload)
}
const tableHeaders = [
    {
        title: "Key",
        key: "Key",
    },
    // {
    //     title: "Size",
    //     key: "Size",
    // },
    {
        title: "Created",
        key: "LastModified",
    },
    {
        title: "Action",
        key: "Action",
        width: 120,
        sortable: false,
    }
];
const subcontractorsListHeaders = [
    {
        title: "Subontractor",
        key: "subcontractor_uid",
        value: (row) => nameById(row.subcontractor_uid),
    },
    {
        title: "Amount",
        key: "amount",
    },
];
const externalEstimatesListHeaders = [
    {
        title: "Name",
        key: "name",
    },
    {
        title: "Amount",
        key: "amount",
    },
    {
        title: "Adjustment Factor",
        key: "default_adjustment_factor",
    },
    // {
    //     title: "NPP AJ",
    //     key: "npp_adjustment_factor",
    // },
];
</script>