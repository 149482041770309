<template>
    <v-container fluid>
        <ReadHeader :current="current" />

        <v-row>
            <v-col>
                <KeyValue
                :title="`Status`"
                :value="getTitleFromValue(entityCore.status)"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Unit Price Book`"
                :value="nameById(entityCore.unit_price_book_uid)"
                ></KeyValue>
            </v-col>
        </v-row>

        <v-row><v-col><v-divider></v-divider></v-col></v-row>

            <v-row>
                <v-col>
                    <h3>Subcontractors</h3>
                </v-col>
            </v-row>

        <v-row>
            <v-col>
                <v-data-table
                    :headers="subcontractorsListHeaders"
                    :items="entityCore.subcontractors"
                    item-key="_id"
                    :items-per-page="100"
                    :hide-default-footer="true"
                    disable-sort
                    >
                </v-data-table>
            </v-col>
        </v-row>
        
        <template v-if="$hasPermission(`supplemental_proposal:create`)">
            <v-row><v-col><v-divider></v-divider></v-col></v-row>

                <v-row>
                    <v-col>
                        <h3>External Estimates</h3>
                    </v-col>
                </v-row>

            <v-row>
                <v-col>
                    <v-data-table
                        :headers="externalEstimatesListHeaders"
                        :items="entityCore.external_estimates"
                        item-key="_id"
                        :items-per-page="100"
                        :hide-default-footer="true"
                        disable-sort
                        >
                    </v-data-table>
                </v-col>
            </v-row>
        </template>
        
    </v-container>
</template>
<script setup>
import { reactive, watch } from 'vue';
// import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useEntityCore, useNameById, useIsCurrentEntityLoaded, useFetchSingle } from "@/assets/modules/composables.js";
import ReadHeader from "@/components/interface/ReadHeader.vue";
import KeyValue from "@/components/interface/Key-Value.vue";
import statusesList from "@/assets/selects/project-work-order-proposal-status.json";

const route = useRoute();
const { nameById } = useNameById();
const { entityCore } = useEntityCore();
const isLoading = reactive({});
const alreadyFetchedData = [];
const { isCurrentEntityLoaded } = useIsCurrentEntityLoaded();
const { fetchSingle } = useFetchSingle(isLoading, alreadyFetchedData);
// const store = useStore();

const current = {
    type: "supplemental_proposal",
    id: route.params.entity_id,
};
function getTitleFromValue(value) {
    const item = statusesList.find(item => item.value === value);
    return item ? item.title : value;
}
watch(isCurrentEntityLoaded, async () => {
    if(isCurrentEntityLoaded.value){
        console.log("entityCore", entityCore.value)
        fetchSingle("unit_price_book", entityCore.value.unit_price_book_uid);
        if(entityCore.value?.subcontractors){
            entityCore.value.subcontractors.forEach((subcontractor) => {
                fetchSingle("subcontractor", subcontractor.subcontractor_uid);
            });
        }
    }
})

const subcontractorsListHeaders = [
    {
        title: "Subontractor",
        key: "subcontractor_uid",
        value: (row) => nameById(row.subcontractor_uid),
    },
    {
        title: "Amount",
        key: "amount",
    },
];
const externalEstimatesListHeaders = [
    {
        title: "Name",
        key: "name",
    },
    {
        title: "Amount",
        key: "amount",
    },
    {
        title: "Adjustment Factor",
        key: "default_adjustment_factor",
    },
    // {
    //     title: "NPP AJ",
    //     key: "npp_adjustment_factor",
    // },
];
</script>